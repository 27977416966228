
import {defineComponent, PropType} from 'vue';
import Popup from '@/components/Popup.vue';
import ArrowUp from '@/assets/icons/icon-arrow-up.svg';
import TruncatedChevron from '@/assets/icons/icon-truncated-chevron.svg';
import MSIcon from '@/assets/icons/icons-product-ms.svg'
import SlingerendeWegIcon from '@/assets/icons/icons-product-slingerende_weg.svg'
import SlingerendeWegOffIcon from '@/assets/icons/icons-product-slingerende_weg_off-road.svg'
import SnelwegIcon from '@/assets/icons/icons-product-snelweg.svg'
import SnowIcon from '@/assets/icons/icons-product-snow.svg'
import StadIcon from '@/assets/icons/icons-product-stad.svg'
import WinterbandenIcon from '@/assets/icons/icons-product-winterbanden.svg'
import TLIcon from '@/assets/icons/icons-product-tl.svg'
import FrontTyreIcon from '@/assets/icons/icons-product-truck-1.svg';
import DriveTyreIcon from '@/assets/icons/icons-product-truck-2.svg';
import TrailerTyreIcon from '@/assets/icons/icons-product-truck-3.svg';
import DiepladerTyreIcon from '@/assets/icons/icons-product-dieplader.svg'
import DistributieTruckTyreIcon from '@/assets/icons/icons-product-distributie_truck.svg'
import RobustTyreIcon from '@/assets/icons/icons-product-robust.svg'
import StadsbusTyreIcon from '@/assets/icons/icons-product-stadsbus.svg'
import { TyreDTO, TyreType, TyreUsage } from "@/types/TyreData";


export default defineComponent({
    components: {Popup},
    expose: ['togglePopup'],
    methods: {
        togglePopup() {
            (this.$refs.popup as typeof Popup).handlePopupState();
        },
        getIcon() {
            switch (this.product.tyreType as TyreType) {
                case TyreType.FRONT: return FrontTyreIcon;
                case TyreType.DRIVE: return DriveTyreIcon;
                case TyreType.TRAILER: return TrailerTyreIcon;
                case TyreType.DIEPLADER: return DiepladerTyreIcon;
                case TyreType.DISTRIBUTIE_TRUCK: return DistributieTruckTyreIcon;
                case TyreType.ROBUST: return RobustTyreIcon;
                case TyreType.STADSBUS: return StadsbusTyreIcon;
            }
        },
      getTyreUsageIcon(icon: string) {
        switch (icon as TyreUsage) {
          case TyreUsage.MS: return MSIcon;
          case TyreUsage.SLINGERENDE_WEG: return SlingerendeWegIcon;
          case TyreUsage.SLINGERENDE_WEG_OFF_ROAD: return SlingerendeWegOffIcon;
          case TyreUsage.SNELWEG: return SnelwegIcon;
          case TyreUsage.SNOW: return SnowIcon;
          case TyreUsage.STAD: return StadIcon;
          case TyreUsage.TL: return TLIcon;
          case TyreUsage.WINTERBANDEN: return WinterbandenIcon;
        }
      }
    },
    props: {
        product: {type: Object as PropType<TyreDTO>, required: true},
        orderUrl: {type: String, required: true},
    },
    computed: {
        ArrowUp() {return ArrowUp;},
        TruncatedChevron() {return TruncatedChevron;},
        EnergyLabelAltText() {
            return `${this.product.productFamily} ${this.product.name} ${this.$t('heuver-albourgh.components.productInformation.energyLabel.altText')}`;
        }
    }
})
