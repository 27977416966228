import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "contact-us__section light-bg" }
const _hoisted_2 = ["innerHTML"]
const _hoisted_3 = { class: "contact-us__subtext" }
const _hoisted_4 = { class: "segmented-button__label" }
const _hoisted_5 = ["innerHTML"]
const _hoisted_6 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ContactPopupButton = _resolveComponent("ContactPopupButton")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("h3", {
      class: "contact-us__title",
      innerHTML: _ctx.mainTitle
    }, null, 8, _hoisted_2),
    _createElementVNode("p", _hoisted_3, _toDisplayString(_ctx.mainSubText), 1),
    _createVNode(_component_ContactPopupButton, {
      class: "contact-us__button segmented-button segmented-button--full segmented-button--green",
      "linked-in-handle": _ctx.linkedInHandle,
      "facebook-handle": _ctx.facebookHandle,
      "instagram-handle": _ctx.instagramHandle,
      "contact-completed-image": _ctx.contactCompletedImage,
      "email-address": _ctx.emailAddress,
      "contact-form-terms-conditions-link": _ctx.contactFormTermsConditionsLink,
      "contact-form-terms-conditions-link-target": _ctx.contactFormTermsConditionsLinkTarget,
      "content-id": _ctx.contentId
    }, {
      default: _withCtx(() => [
        _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.$t('heuver-albourgh.components.contactUs.buttonContact')), 1),
        _createElementVNode("span", {
          class: "segmented-button__icon",
          innerHTML: _ctx.ChevronRight
        }, null, 8, _hoisted_5)
      ]),
      _: 1
    }, 8, ["linked-in-handle", "facebook-handle", "instagram-handle", "contact-completed-image", "email-address", "contact-form-terms-conditions-link", "contact-form-terms-conditions-link-target", "content-id"]),
    _createElementVNode("span", {
      innerHTML: _ctx.TirePattern,
      class: "tire-pattern"
    }, null, 8, _hoisted_6)
  ]))
}