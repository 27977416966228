import { createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, vShow as _vShow, withDirectives as _withDirectives, renderSlot as _renderSlot, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["innerHTML"]
const _hoisted_2 = { class: "hotspot__content-icon" }
const _hoisted_3 = ["src"]
const _hoisted_4 = { class: "hotspot__content-text" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: "hotspot",
    style: _normalizeStyle(_ctx.styleObject)
  }, [
    _createElementVNode("div", {
      ref: "hotspotIcon",
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.handleHotspotOpening())),
      class: _normalizeClass(["hotspot__icon", [
                 _ctx.isActive ? 'hotspot__icon--active' : '',
                 _ctx.isDisabled ? 'hotspot__icon--disabled' : ''
                 ]])
    }, [
      _createElementVNode("span", { innerHTML: _ctx.IconPlus }, null, 8, _hoisted_1)
    ], 2),
    _createElementVNode("div", {
      class: _normalizeClass(["hotspot__content", _ctx.isActive ? 'hotspot__content--active' : '']),
      style: _normalizeStyle(_ctx.transformObject)
    }, [
      _withDirectives(_createElementVNode("div", _hoisted_2, [
        _createElementVNode("img", {
          src: _ctx.icon,
          alt: "Default icon",
          loading: "lazy"
        }, null, 8, _hoisted_3)
      ], 512), [
        [_vShow, _ctx.icon]
      ]),
      _createElementVNode("p", _hoisted_4, [
        _renderSlot(_ctx.$slots, "default")
      ])
    ], 6)
  ], 4))
}