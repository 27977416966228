
import {defineComponent} from "vue";
import IconPlus from "@/assets/icons/icons-plus.svg";
import {mqMaxWidth} from '@/utils/mq';

export default defineComponent({
    props: {
        active: {type: Boolean, default: false, required: false},
        disabled: {type: Boolean, default: false, required: false},
        top: {type: String, required: true},
        left: {type: String, required: true},
        icon: {type: String, required: false},
        zIndexOffset: {type: Number, required: false, default: 0}
    },
    data: function() {
        return {
            isDisabled: this.disabled,
            isActive: this.active
        }
    },
    computed: {
        zIndexObject() {
            return {
                zIndex: (100 - this.zIndexOffset)
            };
        },
        locationObject() {
            return {
                top: this.top + "%",
                left: this.left + "%"
            };
        },
        styleObject() {
            return {...this.zIndexObject, ...this.locationObject};
        },
        transformObject() {
            if(mqMaxWidth('lt').matches) {
                return {transform: "translate(-" + this.left + "%, 25px)"}
            } else {
                return {transform: "translate(-50%, 25px)"}
            }
        },
        IconPlus() {return IconPlus;}
    },
    methods: {
        handleClickEvent(e: MouseEvent) {
            if (this.$refs.hotspotIcon.contains(e.target)) return;
            this.isActive = false;
        },
        handleHotspotOpening() {
            if (this.isDisabled) return;
            this.isActive = !this.isActive;
        }
    },
    mounted() {
        window.addEventListener("click", this.handleClickEvent)
    },
    unmounted() {
        window.removeEventListener("click", this.handleClickEvent)
    }
});
