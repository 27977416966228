
import {defineComponent} from 'vue';
import IconChevronDown from '@/assets/icons/icons-chevron-down.svg?inline'
import IconPlay from '@/assets/icons/icon-play-icon.svg?inline'

export default defineComponent({
    props: {
        eyeBrow: {$type: String, required: true},
        text: {$type: String, required: true},
        lblDiscoverMore: {$type: String, required: true},
        lblWatchVideo: {$type: String, required: true},
        videoPreviewPoster: {$type: String, required: true},
        videoPreviewUrl: {$type: String, required: true},
        videoFullUrl: {$type: String, required: false},
  },
  computed: {
      IconChevronDown() {return IconChevronDown;},
      IconPlay() {return IconPlay;},
      /**
        * Detect if the current device is running iOS.
        * iOS needs specific handling for fullscreen due to limited Fullscreen API support.
        */
      checkIfiOS() {
        return /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
      },
  },
  methods: {
    exitFullscreen() {
      const videoPreview = this.$refs.videoPreview as HTMLVideoElement;
      const videoFull = this.$refs.videoFull as HTMLVideoElement & {
        webkitDisplayingFullscreen?: boolean;
      };

      if (!document.fullscreenElement || (this.checkIfiOS && videoFull.webkitDisplayingFullscreen === false)) {
        videoFull.pause();
        videoFull.currentTime = 0;
        videoFull.classList.remove("active");

        videoPreview.play();

        document.removeEventListener("fullscreenchange", this.exitFullscreen);
      }
    },
    enterFullScreen() {
      if (!this.$refs.videoFull) return;
      const videoPreview = this.$refs.videoPreview as HTMLVideoElement;
      const videoFull = this.$refs.videoFull as HTMLVideoElement & {
        webkitEnterFullscreen?: () => void;
        onwebkitfullscreenchange?: (this: HTMLVideoElement, ev: Event) => any;
      };

      videoPreview.pause();
      videoFull.classList.add("active");
      videoFull.play();


      // iOS: Use WebKit's fullscreen API,  standard Fullscreen API is not supported
      if (this.checkIfiOS) {
          videoFull.addEventListener('webkitendfullscreen', (e) => {
            this.exitFullscreen();
          });
      } else {
        // Non-iOS: Use standard Fullscreen API
        videoFull.requestFullscreen()
            .then(() => {
              document.addEventListener("fullscreenchange", this.exitFullscreen);
            });
      }
    }
  }
});
