
import {defineComponent} from 'vue';
import IconCross from "@/assets/icons/icon-cross.svg";

export default defineComponent({
    expose: ['handlePopupState'],
    props: {
        popupOpen: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            isPopupOpen: this.popupOpen,
        }
    },
    methods: {
        closePopup() {
            this.isPopupOpen = false;
            document.body.classList.toggle("no-scroll", this.isPopupOpen);
        },
        handlePopupState() {
            this.isPopupOpen = !this.isPopupOpen;
            document.body.classList.toggle("no-scroll", this.isPopupOpen);
        },
        getIconCross() {return IconCross;}
    }
})
