/* eslint-disable */
require("./scss/main.scss");
document.addEventListener("touchstart", function() {}, false);

declare let __webpack_public_path__: string;
__webpack_public_path__ = _mgnl.contextPath + "/" + __webpack_public_path__;
/* eslint-enable */

// Dependencies
// - Vue
import {createApp} from 'vue';
// - Vue-i18n
import {createI18n} from 'vue-i18n'
import process from '@/utils/propertyFileProcessor'
// - Swiper
import {SwiperSlide} from 'swiper/vue/swiper-vue';
// - Formkit
import {plugin, defaultConfig} from '@formkit/vue';
import {createFloatingLabelsPlugin} from '@formkit/addons';

// Project components
import Navigation from "@/components/Navigation.vue";
import LanguageSelector from "@/components/languageSelector.vue";
import VideoHero from '@/components/VideoHero.vue';
import ImageHero from '@/components/ImageHero.vue';
import UspSlider from "@/components/UspSlider.vue";
import ProductSlider from "@/components/ProductSlider.vue";
import ProductSlide from '@/components/ProductSlide.vue';
import ProductHotSpot from "@/components/ProductHotSpot.vue";
import ProductPopup from '@/components/ProductPopup.vue';
import InnovationTabs from '@/components/InnovationTabs.vue';
import ContactComponent from '@/components/ContactComponent.vue';
import ContactPopup from '@/components/ContactPopup.vue';
import ProductWrapper from '@/components/ProductsWrapper.vue';
import ProductInfo from '@/components/ProductInfo.vue';


// @ts-ignore
import enProperties from '../../i18n/module-heuver-albourgh-frontend_en.properties';
// @ts-ignore
import frProperties from '../../i18n/module-heuver-albourgh-frontend_fr.properties';
// @ts-ignore
import nlProperties from '../../i18n/module-heuver-albourgh-frontend_nl.properties';
// @ts-ignore
import deProperties from '../../i18n/module-heuver-albourgh-frontend_de.properties';
// @ts-ignore
import esProperties from '../../i18n/module-heuver-albourgh-frontend_es.properties';
// @ts-ignore
import itProperties from '../../i18n/module-heuver-albourgh-frontend_it.properties';

const en = process(enProperties);
const fr = process(frProperties);
const nl = process(nlProperties);
const de = process(deProperties);
const es = process(esProperties);
const it = process(itProperties);

const i18n = createI18n({
	locale: _mgnl.language,
	fallbackLocale: 'en',
	messages: {
		en,
		fr,
		nl,
		de,
		es,
		it
	},
});

const app = createApp({
	components: {
		Navigation,
		LanguageSelector,
		VideoHero,
		ImageHero,
		SwiperSlide,
		ProductSlide,
		ProductSlider,
		ProductHotSpot,
		ProductPopup,
		UspSlider,
		InnovationTabs,
		ContactComponent,
		ContactPopup,
		ProductWrapper,
		ProductInfo,
	}
});

app.use(plugin, defaultConfig({plugins: [createFloatingLabelsPlugin({useAsDefault: true})]}));
app.use(i18n);

app.config.performance = true;
app.config.compilerOptions.comments = true;

app.mount('#app');

