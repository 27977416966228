import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "product-info__container" }
const _hoisted_2 = { class: "product-info__eyebrow" }
const _hoisted_3 = { class: "product-info__product-family" }
const _hoisted_4 = ["src"]
const _hoisted_5 = { class: "product-info__text" }
const _hoisted_6 = ["href"]
const _hoisted_7 = { class: "segmented-button__label product-info__button__label" }
const _hoisted_8 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("span", _hoisted_2, "-- " + _toDisplayString(_ctx.eyebrow), 1),
    _createElementVNode("h2", _hoisted_3, _toDisplayString(_ctx.productFamily), 1),
    (_ctx.image)
      ? (_openBlock(), _createElementBlock("img", {
          key: 0,
          class: "product-info__product-image",
          src: _ctx.image,
          alt: ""
        }, null, 8, _hoisted_4))
      : _createCommentVNode("", true),
    _createElementVNode("span", _hoisted_5, _toDisplayString(_ctx.description), 1),
    _createElementVNode("a", {
      class: "product-info__button segmented-button segmented-button--full segmented-button--green",
      href: _ctx.link
    }, [
      _createElementVNode("span", _hoisted_7, _toDisplayString(_ctx.$t('heuver-albourgh.components.productInfo.button.discoverMore')), 1),
      _createElementVNode("span", {
        class: "segmented-button__icon",
        innerHTML: _ctx.ArrowRight
      }, null, 8, _hoisted_8)
    ], 8, _hoisted_6)
  ]))
}