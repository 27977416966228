
import {defineComponent, PropType} from 'vue';
import ProductPopup from '@/components/ProductPopup.vue';
import ArrowUp from '@/assets/icons/icon-arrow-up.svg'
import {TyreDTO} from '@/types/TyreData';

export default defineComponent({
    components: {ProductPopup},
    props: {
        product: {type: Object as PropType<TyreDTO>, required: true},
        orderUrl: {type: String, required: true},
        labelInformation: {type: String, required: true},
        labelOrder: {type: String, required: true}
    },
    computed: {
        ArrowUp() {return ArrowUp;},
        ImageAltText() {
            return `${this.$t('heuver-albourgh.components.productSlider.imageAltText')} ${this.product.productFamily} ${this.product.name}`;
        }
    }
})
