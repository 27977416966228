
import {defineComponent} from "vue";
import GlobeIcon from "@/assets/icons/icons-globe.svg"
import TriangleDown from "@/assets/icons/icons-triangle-down.svg"

export default defineComponent({
    props: {
        current_locale: {
            type: String,
            default: "en"
        },
        current_language_name: {
            type: String,
            default: "English"
        }
    },
    data() {
        return {
            isLanguagePopupOpen: false
        }
    },
    methods: {
        toggleLanguagePopup() {
            this.isLanguagePopupOpen = !this.isLanguagePopupOpen;
        },
        handleClick(e: MouseEvent) {
            if((this.$refs.languageSelector as Node).contains(e.target as Node)) return;
            this.isLanguagePopupOpen= false;
        }
    },
    mounted() {
        window.addEventListener("click", this.handleClick);
    },
    unmounted() {
        window.removeEventListener("click", this.handleClick);
    },
    computed: {
        GlobeIcon() {return GlobeIcon;},
        TriangleDown() {return TriangleDown;},
    }
});

