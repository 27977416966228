import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, renderSlot as _renderSlot, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "language-selector" }
const _hoisted_2 = ["innerHTML"]
const _hoisted_3 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: "language-selector__current-language",
      ref: "languageSelector",
      onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.toggleLanguagePopup && _ctx.toggleLanguagePopup(...args)))
    }, [
      _createElementVNode("span", {
        innerHTML: _ctx.GlobeIcon,
        class: "globe-icon"
      }, null, 8, _hoisted_2),
      _createElementVNode("div", null, _toDisplayString(_ctx.current_language_name), 1),
      _createElementVNode("span", {
        innerHTML: _ctx.TriangleDown,
        class: "triangle-icon"
      }, null, 8, _hoisted_3)
    ], 512),
    _createElementVNode("ul", {
      class: _normalizeClass(["language-selector__list", _ctx.isLanguagePopupOpen ? 'language-selector__list--active' : '']),
      ref: "languagePopup"
    }, [
      _renderSlot(_ctx.$slots, "default")
    ], 2)
  ]))
}