export type TyreData = {
  "@name": string
  "@path": string
  "@id": string

  productFamily: string
  name: string
  description: string
  tyreType: TyreType | string
  productImage: string | ImageReference
  productFeatures: string[]
  energyLabelImage: string | ImageReference
  energyLabelUrl: string
  productTableEntries: ProductTableEntry
}

export type ImageReference = {
  "@name": string
  "@path": string
  "@id": string
  "@link": string
  metadata: ImageMetaData
  renditions: {[key: string]: ImageRendition }
}

export type ImageMetaData = {
  fileName: string
  mimeType: string
  fileSize: string
  height: string
  width: string
  format: string
  creator: string[]
  date: string
  created: string
  modified: string
}

export type ImageRendition = {
  mimeType: string
  link: string
}

export type ProductTableEntry = {
  "@nodes": string[]
}

export type ProductTableEntryRow = {
  index: string
  availability: string
  size: string
  articleCode: string
  pdfUrl: string
}
/* eslint-disable no-unused-vars */
export enum TyreType {
  FRONT = "1",
  DRIVE = "2",
  TRAILER = "3",
  DIEPLADER = "4",
  DISTRIBUTIE_TRUCK = "5",
  ROBUST = "6",
  STADSBUS = "7",
}
/* eslint-enable */

export enum TyreUsage {
  MS = "1",
  SLINGERENDE_WEG = "2",
  SLINGERENDE_WEG_OFF_ROAD = "3",
  SNELWEG = "4",
  SNOW = "5",
  STAD = "6",
  TL = "7",
  WINTERBANDEN = "8",
}

export type TyreDTO = {
  productFamily: string
  name: string
  description: string
  tyreType: TyreType
  firstIcon: TyreUsage
  secondIcon: TyreUsage
  thirdIcon: TyreUsage
  fourthIcon: TyreUsage
  productImage: string
  energyLabelImage: string
  energyLabelUrl: string
  productFeatures: string[]
  productTableEntries: ProductTableEntryRow[]
}
