import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "image-hero__tag" }
const _hoisted_2 = ["innerHTML"]
const _hoisted_3 = ["href"]
const _hoisted_4 = { class: "segmented-button__label" }
const _hoisted_5 = ["innerHTML"]
const _hoisted_6 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, _toDisplayString(_ctx.eyeBrow), 1),
    _createElementVNode("h1", {
      class: "image-hero__text",
      innerHTML: _ctx.text
    }, null, 8, _hoisted_2),
    (_ctx.lblDiscoverMore)
      ? (_openBlock(), _createElementBlock("a", {
          key: 0,
          class: "image-hero__button-discover segmented-button segmented-button--full segmented-button--green",
          href: _ctx.discoverMoreLink
        }, [
          _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.lblDiscoverMore), 1),
          _createElementVNode("span", {
            class: "segmented-button__icon",
            innerHTML: _ctx.IconChevronDown
          }, null, 8, _hoisted_5)
        ], 8, _hoisted_3))
      : _createCommentVNode("", true),
    _createElementVNode("img", {
      class: "image-hero__background",
      src: _ctx.imageCover,
      alt: ""
    }, null, 8, _hoisted_6)
  ], 64))
}