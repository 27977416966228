import { renderSlot as _renderSlot, mergeProps as _mergeProps, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ContactPopup = _resolveComponent("ContactPopup")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("button", _mergeProps(_ctx.$attrs, {
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.showContactPopup()))
    }), [
      _renderSlot(_ctx.$slots, "default")
    ], 16),
    _createVNode(_component_ContactPopup, {
      ref: "popup",
      contactEmailAddress: _ctx.emailAddress,
      contactCompletedImage: _ctx.contactCompletedImage,
      instagramHandle: _ctx.instagramHandle,
      facebookHandle: _ctx.facebookHandle,
      linkedInHandle: _ctx.linkedInHandle,
      contactFormTermsConditionsLink: _ctx.contactFormTermsConditionsLink,
      contactFormTermsConditionsLinkTarget: _ctx.contactFormTermsConditionsLinkTarget,
      "content-id": _ctx.contentId
    }, null, 8, ["contactEmailAddress", "contactCompletedImage", "instagramHandle", "facebookHandle", "linkedInHandle", "contactFormTermsConditionsLink", "contactFormTermsConditionsLinkTarget", "content-id"])
  ], 64))
}