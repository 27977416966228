
import {defineComponent} from 'vue';
import {FormKit} from '@formkit/vue';
import ChevronRight from '@/assets/icons/icons-chevron-right.svg'
import EmailIcon from '@/assets/icons/icon-email.svg'
import InstagramIcon from '@/assets/icons/icon-instagram.svg'
import FacebookIcon from '@/assets/icons/icon-facebook.svg'
import LinkedInIcon from '@/assets/icons/icon-linkedin.svg'
import Popup from '@/components/Popup.vue';
import axios from 'axios';

const phoneRegEx = new RegExp("^\\+?\\d{1,4}?[-.\\s]?\\(?\\d{1,3}?\\)?[-.\\s]?\\d{1,4}[-.\\s]?\\d{1,4}[-.\\s]?\\d{1,9}$")

export default defineComponent({
    components: {Popup, FormKit},
    expose: ['togglePopup'],
    props: {
        contactEmailAddress: {type: String, required: true},
        contactCompletedImage: {type: String, required: true},
        instagramHandle: {type: String, required: true},
        facebookHandle: {type: String, required: true},
        linkedInHandle: {type: String, required: true},
        contactFormTermsConditionsLink: {type: String, required: true},
        contactFormTermsConditionsLinkTarget: {type: String, required: true},
        contentId: {type: String, required: true}
    },
    data() {
        return {
            successfulSubmit: false
        }
    },
    methods: {
        togglePopup() {
            this.$refs.popup.handlePopupState()
        },
        finishSubmission() {
            this.$formkit.reset("formkit-form");
            this.successfulSubmit = false;
            this.togglePopup();
            window.scrollTo(0, 0);
        },
        async submitForm(values: object) {
            const button = (this.$refs.submitButton as HTMLButtonElement);
            button.disabled = true;

            axios.post(`/.rest/form/contact-form-albourgh/v1?uuid=${this.contentId}&lang=${this.$i18n.locale}`, JSON.stringify(values), {
                headers: {
                    'Content-Type': 'application/json;charset=UTF-8'
                }})
                .then(() => {
                    this.successfulSubmit = true;
                })
                .finally(() => {
                    button.disabled = false;
                });
        },
        validatePhoneNumber(input: HTMLInputElement) {
            return phoneRegEx.test(input.value);
        }
    },
    computed: {
        ChevronRight() {return ChevronRight;},
        EmailIcon() {return EmailIcon;},
        InstagramIcon() {return InstagramIcon;},
        FacebookIcon() {return FacebookIcon;},
        LinkedInIcon() {return LinkedInIcon;},

        siteName() {
            return _mgnl.siteName;
        }
    }
})
