import { createElementVNode as _createElementVNode, renderSlot as _renderSlot, mergeProps as _mergeProps, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Teleport as _Teleport, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "popup__content-wrapper" }
const _hoisted_2 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createBlock(_Teleport, { to: "#app" }, [
    (_ctx.isPopupOpen)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          id: "popup",
          class: _normalizeClass(["popup", _ctx.isPopupOpen ? 'popup--visible' : '']),
          onOpenPopup: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.handlePopupState && _ctx.handlePopupState(...args)))
        }, [
          _createElementVNode("div", _hoisted_1, [
            _createElementVNode("button", {
              class: "popup__close-btn",
              onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.closePopup && _ctx.closePopup(...args))),
              "aria-label": "Close popup button",
              innerHTML: _ctx.getIconCross()
            }, null, 8, _hoisted_2),
            _createElementVNode("div", _mergeProps({ id: "popup__content-slot" }, _ctx.$attrs), [
              _renderSlot(_ctx.$slots, "default")
            ], 16)
          ])
        ], 34))
      : _createCommentVNode("", true)
  ]))
}