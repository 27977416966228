
import { defineComponent } from "vue";
import ArrowLeft from "@/assets/icons/arrow-left.svg";
import ArrowRight from "@/assets/icons/arrow-right.svg";
import { Swiper } from "swiper/vue/swiper-vue";
import { Navigation } from "swiper";

export default defineComponent({
    components: {
        Swiper,
    },
    data() {
        return {
            swiper: null,
            slideCount: 0,
            currentIndex: 0,
        };
    },
    methods: {
        onSwiper(swiper: any) {
            this.swiper = swiper;
            this.slideCount = swiper.slides.length;
            this.currentIndex = swiper.activeIndex;
            this.updatePagination(0);
        },
        whenSlideChanged(swiper: any) {
            this.updatePagination(swiper.activeIndex);
        },
        updatePagination(pageNumber: number): void {
            this.currentIndex = pageNumber;
            (Array.from(this.$refs.paginationItems.children) as Element[]).forEach(
                (el: Element, index: number) => {
                    el.classList.toggle(
                        "swiper-layout__pagination-item--active",
                        index == pageNumber
                    );
                }
            );
        },
    },
    setup() {
        let randomString = Math.random().toString(36).slice(2);
    
        return {
            randomString: randomString,
            slidesPerView: 1,
            allowTouchMove: true,
            modules: [Navigation],
            speed: 500,
            navigation: {
                prevEl: "#previous" + randomString,
                nextEl: "#next" + randomString,
            },
            breakpoints: {
                769: {
                    allowTouchMove: false,
                },
            },
        };
    },
    computed: {
        ArrowLeft() { return ArrowLeft; },
        ArrowRight() { return ArrowRight; },
    },
});
