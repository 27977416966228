
import {defineComponent} from 'vue';
import ChevronRight from '@/assets/icons/icons-chevron-right.svg'
import TirePattern from '@/assets/icons/icons-tire-pattern.svg'
import ContactPopupButton from '@/components/ContactPopupButton.vue';

export default defineComponent({
    components: {ContactPopupButton},
    props: {
        mainTitle: {type: String, required: true},
        mainSubText: {type: String, required: true},
        mainButtonLabel: {type: String, required: true},
        emailAddress: {type: String, required: true},
        contactCompletedImage: {type: String, required: false},
        instagramHandle: {type: String, required: true},
        facebookHandle: {type: String, required: true},
        linkedInHandle: {type: String, required: true},
        contactFormTermsConditionsLink: {type: String, required: true},
        contactFormTermsConditionsLinkTarget: {type: String, required: true},
        contentId: {type: String, required: true}
    },
    computed: {
        ChevronRight() {return ChevronRight;},
        TirePattern() {return TirePattern;}
    }
})
