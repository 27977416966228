interface KeyValue {
    key: string;
    value: string;
}

const choiceRegExp = new RegExp(/^.*({[\d], ?choice).*$/gm);

const isComment = (line: string) => line.startsWith('#');
const isEmpty = (line: string) => line.trim() === '';

// eslint-disable-next-line
const asKeyValue: (line: string) => Partial<KeyValue> = (line) => {
    let {0: key, 1: value}: string[] = line.split('=');
    if(key) {
        key = key.trim();
    }
    if(value) {
        value = value.trim();
    }
    return {key, value};
};

const hasKeyAndValue = (keyValue: Partial<KeyValue>): keyValue is KeyValue => {
    const {key, value} = keyValue;
    return !!(key && value);
};

const isChoice = (value: string) => choiceRegExp.test(value);

const valueToChoiceFormat = (value: string) => {
    const lastBracket = value.lastIndexOf('}');
    if (lastBracket === -1) {
        return value;
    }
    const choiceString = value.substring(0, lastBracket);
    const restString = value.substring(lastBracket + 1);
    const properties = choiceString.split(',');
    if (properties.length > 2) {
        const options = properties[2].split('|');
        const newOptions: string[] = [];
        options.forEach((option) => {
            const replaced = option.replace(/[\d]([#<])/, '');
            newOptions.push(`${replaced}${restString}`);
        });
        value = newOptions.join(' | ');
    }
    return value;
};


const process = (file: any) => {
    const lines: string[] = file.split('\n');
    const translations: any = {};
    lines
        .filter((line) => !isComment(line) && !isEmpty(line))
        .forEach((line) => {
            const keyValue: Partial<KeyValue> = asKeyValue(line);
            if (!hasKeyAndValue(keyValue)) {
                return;
            }
            const {key} = keyValue;
            let {value} = keyValue;
            if (isChoice(value)) {
                value = valueToChoiceFormat(value);
            }
            if (!Object.prototype.hasOwnProperty.call(translations, key)) {
                translations[key] = value.replace(/\r?\n|\r/, '');
            }
        });
    return translations;
};
export default process;
