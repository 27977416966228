
import {defineComponent} from 'vue';
import ContactPopup from '@/components/ContactPopup.vue';

export default defineComponent({
    components: {ContactPopup},
    props: {
        emailAddress: {type: String, required: true},
        contactCompletedImage: {type: String, required: false},
        instagramHandle: {type: String, required: true},
        facebookHandle: {type: String, required: true},
        linkedInHandle: {type: String, required: true},
        contactFormTermsConditionsLink: {type: String, required: true},
        contactFormTermsConditionsLinkTarget: {type: String, required: true},
        contentId: {type: String, required: true}
    },
    data() {
        return {}
    },
    methods: {
        showContactPopup() {
            this.$refs.popup.togglePopup();
        }
    }
})
