
import {defineComponent} from 'vue';
import IconChevronDown from '@/assets/icons/icons-chevron-right.svg?inline'
import IconPlay from '@/assets/icons/icon-play-icon.svg?inline'

export default defineComponent({
    props: {
      eyeBrow: {$type: String, required: true},
      text: {$type: String, required: true},
      lblDiscoverMore: {$type: String, required: true},
      discoverMoreLink: {$type: String, required: true},
      imageCover: {$type: String, required: true},

    },
    computed: {
        IconChevronDown() {return IconChevronDown;},
        IconPlay() {return IconPlay;},
    }
});
