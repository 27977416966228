import { createElementVNode as _createElementVNode, renderSlot as _renderSlot, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "innovation-tabs__swiper swiper-layout swiper-layout--pagination swiper-layout--dark" }
const _hoisted_2 = ["id", "innerHTML"]
const _hoisted_3 = ["id", "innerHTML"]
const _hoisted_4 = {
  key: 0,
  class: "swiper-layout__pagination-count"
}
const _hoisted_5 = {
  class: "swiper-layout__pagination innovation-tabs__pagination pagination-items",
  ref: "paginationItems"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_swiper = _resolveComponent("swiper")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: "innovation-tabs__swiper-button-previous swiper-layout__button-previous",
      id: 'previous' + _ctx.randomString,
      innerHTML: _ctx.ArrowLeft
    }, null, 8, _hoisted_2),
    _createVNode(_component_swiper, {
      class: "swiper-layout__swiper innovation-tabs__tab-items",
      slidesPerView: _ctx.slidesPerView,
      allowTouchMove: _ctx.allowTouchMove,
      modules: _ctx.modules,
      navigation: _ctx.navigation,
      createElements: false,
      breakpoints: _ctx.breakpoints,
      speed: _ctx.speed,
      onSwiper: _ctx.onSwiper,
      onSlideChange: _ctx.whenSlideChanged
    }, {
      default: _withCtx(() => [
        _renderSlot(_ctx.$slots, "tabs")
      ]),
      _: 3
    }, 8, ["slidesPerView", "allowTouchMove", "modules", "navigation", "breakpoints", "speed", "onSwiper", "onSlideChange"]),
    _createElementVNode("div", {
      class: "innovation-tabs__swiper-button-next swiper-layout__button-next",
      id: 'next' + _ctx.randomString,
      innerHTML: _ctx.ArrowRight
    }, null, 8, _hoisted_3),
    (_ctx.swiper)
      ? (_openBlock(), _createElementBlock("p", _hoisted_4, _toDisplayString(this.currentIndex + 1) + " OF " + _toDisplayString(this.slideCount), 1))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_5, [
      _renderSlot(_ctx.$slots, "pagination")
    ], 512)
  ]))
}