
import { defineComponent } from "vue";
import ArrowRight from '@/assets/icons/icons-arrow-right.svg'
export default defineComponent({
  props: {
    product: {
      type: Object as any
    },
    eyebrow: {
      type: String
    },
    productFamily: {
      type: String
    },
    image: {
      type: String
    },
    description: {
      type: String
    },
    link: {
      type: String
    },
    buttonLabel: {
      type: String
    }

  },
  computed: {
    ArrowRight() {  return ArrowRight }
  }
})
